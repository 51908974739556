<!-- 二级路由 -->
<template>
	<div class="content has_breadcrumb">
		<base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
		<GgPage pageType="2" :title='title'>
			<template v-slot:left>
				<gg-flex-menus :menus="menuDatas" @select="menuSelect" :default-active="activeIndex" />
				
			</template>
			<GgPageHeader :editimg="false" :student='true' v-if='shuaxin' :userInfo="userInfo" :title="`${userInfo.firstName||''} ${userInfo.lastName||''}`"
				:menuData="dropdownData" :subTitle1="userInfo.studentId" @_uploadCallbackLogo="_uploadCallbackLogo"
				@menuCallback="menuClick">
				<div v-if="!!formDialogLocations.checkboxList" slot="title-label" class="title-label"
					@click="formDialogLocations.viewLocations = true">
					<span>{{ viewLabel }}</span>
			<!-- 		<img v-if="formDialogLocations.checkboxList.length > 1" class="icon-view"
						src="@/assets/images/icons/icon-view.png" alt="" /> -->
				</div>
				<div slot="info">
					<div class="title-status mar-b-0" :class="{ 'title-status-red': userInfo.status === 'Withdrawn' }">
						{{ userInfo.status | firstUpperCase }}
					</div>
				</div>
			</GgPageHeader>
			<!-- edit student -->
			<BaseFormDialog1 v-model="formDialogVisible" v-if="formDialogVisible" :title="formDialogTitle"
				:data="formDataList" @submit="handleSubmit" :renderPass="renderPass" :submitBtnTxt="formDialogBtnTxt"
				textReminder="* indicates a required field" ref="baseForm">
				<template slot="renderHtml" slot-scope="scope">
					<gg-input :disabled='isStudentIdEdit' :class="[{ float: scope.data.float }, scope.data.className]"
						:key="scope.data.key" :ref="scope.data.key" v-model="scope.data.value"
						:placeholder="scope.data.placeholder" 
						v-if="scope.data.key =='studentId'" />
			
					<div class="checkBoxCon" v-if="scope.data.key === 'auto' ">
						<md-checkbox @change='changeAuto' v-model="scope.data.value" true-value="1" false-value="0" class="md-primary">Auto
						</md-checkbox>
						<el-tooltip :visible-arrow="false" placement="bottom" effect="light" popper-class="text"
							content="Use student ID auto generated by the system">
							<div class="prompt">
								<img :src="require('@/assets/images/prompt.png')" />
							</div>
						</el-tooltip>
					</div>
					
					
					<el-tooltip :visible-arrow="false" placement="bottom" popper-class="text"
						content="Cannot edit location if the student is assigned to a class" v-if="userInfo.inClass">
					<!-- 	<baseGGSelect v-if="scope.data.key === 'schoolId'" v-model="scope.data.value"
							placeholder="Location *" ref="schoolId" class="float" :disabled='userInfo.inClass'>
							<md-option v-for="(item, index) in locationList" :key="index" :value="item.id">
								{{ item.value }}
							</md-option>
						</baseGGSelect> -->
						<div v-if="scope.data.key === 'schoolId'" class="md-field gg_select el-tooltip float md-theme-default md-disabled md-has-value spec" aria-describedby="el-tooltip-4959" tabindex="0"
						style="line-height: 2;"	><label data-v-513b0464="" for="undefined">Location *</label>
								<span v-if="scope.data.key === 'schoolId'"  ref="schoolId" class="float" :disabled='true'>
									<span v-for="(item, index) in locationList" v-if="item.id==scope.data.value" style=" white-space: nowrap;">
										{{ item.value }}
									</span>
								</span>
							</div>
					</el-tooltip>
					<baseGGSelect v-if="scope.data.key === 'schoolId' && !userInfo.inClass" v-model="scope.data.value"
						placeholder="Location *" ref="schoolId" class="float">
						<md-option v-for="(item, index) in locationList" :key="index" :value="item.id">
							{{ item.value }}
						</md-option>
					</baseGGSelect>
				</template>
			</BaseFormDialog1>
			<!-- view locations -->
			<gg-Dialog :append-to-body="false"   v-if='formDialogLocations.viewLocations' :visible.sync="formDialogLocations.viewLocations" :show-close="true"
				title="Locations">
				<div class="dialog-content">
					<CheckboxGroup :checkTitle="formDialogLocations.checkTitle"
						:checkedData="formDialogLocations.checkedData" :checkboxList="formDialogLocations.checkboxList"
						@check="handleCheck" />
				</div>
			</gg-Dialog>
			<router-view></router-view>
		</GgPage>
		<Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
	</div>
</template>

<script>
	import {
		Ajax,
		Utils
	} from "@/common";
	import {
		mapState
	} from "vuex";
	export default {
		components: {},
		data() {
			return {
				people:false,
				menuDatas: [{
					name: "",
					key: "",
				}, ],
				breadcrumbData: [ ],
				activeIndex: "",
				userInfo: {},
				formDialogLocations: {
					viewLocations: false,
					checkTitle: "Raffles Kidz International",
					checkedData: [],
					checkboxList: [],
				},
				snackbar: {
					isShow: false,
					content: "",
				},
				messageObj: {
					Delete: {
						title: "Delete student ?",
						desc: "Once the student is deleted, you will not be able to recover the student data.",
						cancelTxt: "Cancel",
						confirmTxt: "Delete",
						snackbarContent: "Student has been deleted",
					},
					Deferred: {
						title: "Defer student ?",
						desc: "Once deferred, this student will move to the deferred students. Student data will remain in the system.",
						cancelTxt: "Cancel",
						confirmTxt: "Defer",
						snackbarContent: "Student has been deferred",
					},
					Enrolled: {
						title: "Return student ?",
						desc: "Once returned, This student will move to the enrolled students.",
						cancelTxt: "Cancel",
						confirmTxt: "Return",
						snackbarContent: "Student has been enrolled",
					},
					Returned: {
						title: "Return student ?",
						desc: "Once returned, This student will move to the enrolled students.",
						cancelTxt: "Cancel",
						confirmTxt: "Return",
						snackbarContent: "Student has been returned",
					},
					Withdrawn: {
						title: "Withdraw student ?",
						desc: "Once Withdrawn, the student will be removed from assigned class. The student's family users will be disconnected with the system.   ",
						cancelTxt: "Cancel",
						confirmTxt: "Withdraw",
						snackbarContent: "Student has been Withdrawn",
					},
				},
				className: JSON.parse(sessionStorage.getItem("studentInfo")).className,
				locationName: JSON.parse(sessionStorage.getItem("studentInfo"))
					.locationName,
				currentStudentData: {}, // 当前学生信息
				formDialogVisible: false,
				formDataList: [{
						placeholder: "First name *",
						key: "firstName",
						value: "",
						autofocus: true,
						float: true,
						reg: /\S/,
						errorText: "First name is required",
					},
					{
						placeholder: "Last name *",
						key: "lastName",
						value: "",
						float: true,
						reg: /\S/,
						errorText: "Last name is required",
					},
					{
						placeholder: "Student ID *",
						key: "studentId",
						value: "",
						float: true,
						className: "studentId",
						reg: /\S/,
						errorText: "Student ID is required",
						renderHtml: true,
					},
					{
						key: "auto",
						value: false,
						float: true,
						renderHtml: true,
					},
					{
						placeholder: "Location *",
						key: "schoolId",
						value: null,
						renderHtml: true,
					},
				],
				slotData: ["schoolId"],
				renderPass: true,
				formDialogTitle: "",
				formDialogBtnTxt: "",
				locationList: [],
				title: 'Students',
				is_first: false,
				isStudentIdEdit: false,
				errorObj: {},
				shuaxin:true,
				studentT:true
			};
		},
		filters: {
			firstUpperCase: Utils.firstUpperCase,
		},
		watch: {
			formDataList: {
				handler() {
					this.$nextTick(() => {
						// if (!this.is_first) {
						// 	this.is_first = true
						// 	this.renderPass = false
						// 	return false
						// }
						let renderPass = this.slotData.every(
							(item) => this.$refs[item] && !!this.$refs[item].value
						);
						// this.renderPass = renderPass;

					});
				},
				deep: true,
			},
		},
		computed: {
			...mapState({
				breadcrumb: (state) => state.breadcrumb,
				user: (state) => state.user,
			}),
			studentId() {
				return JSON.parse(sessionStorage.getItem("studentInfo")).studentId;
			},
			pageType() {
				return JSON.parse(sessionStorage.getItem("studentInfo")).type;
			},
			viewLabel() {
				let checkedDataLen = this.formDialogLocations.checkedData.length,
					checkboxListLen = this.formDialogLocations.checkboxList.length;
				if (checkedDataLen == 1) {
					return this.formDialogLocations.checkedData[0];
				} else if (checkedDataLen == checkboxListLen) {
					return "All  locations";
				} else {
					return checkedDataLen + "locations";
				}
			},
			dropdownData() {
				return []

			},
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				let menuDatas = to.meta.menuDatas;
				// if (vm.$route.query.people) {
				// 	vm.title = 'People'
				// 	vm.people=true
				// } else if (vm.$route.query.class) {
				// 	vm.title = 'Classes'
				// 	vm.menuData = []
				// } else {
				// 	vm.title = 'Students';
				// }
				vm.title = 'People'
				vm.menuDatas = menuDatas;
				vm.activeIndex = to.name;
				vm.breadcrumbData = vm.breadcrumb.breadcrumbData;
			});
		},
		methods: {
			changeAuto(val) {
				if (val == 1) {
					Ajax.get("/usermanage/student/autoGenerateStudentId", {
							userId: this.user.userId
						})
						.then((res) => {
							if (res.code != '0000') {
								this.handleSuspend(res.message);
							}
							this.formDataList.forEach((item) => {
								if (item.key == 'studentId') {
									item.value = res.data.studentId
									this.isStudentIdEdit = true
								}
							});

						})
						.catch(() => {
							this.loading = false;
						});
				} else {
					this.isStudentIdEdit = false
					this.formDataList.forEach((item) => {
						if (item.key == 'studentId') {
							// item.value = ""
						}
					});
				}

			},
			menuSelect(key) {
				if (JSON.parse(sessionStorage.getItem('isChanged'))) {

				} else {
					this.activeIndex = key;
				}
				if (this.$route.query.people) {
					this.$router.push({
						name: key,
						query: {
							people: true
						}
					});

				} else {
					this.$router.push({
						name: key
					});
				}
			},
			_getData() {
				this.loading = true;
				Ajax.get("/usermanage/student/selectByStuId", {
						stuId: this.studentId,
					})
					.then((res) => {
						this.currentStudentData = {
							...res.data
						};
						this.userInfo = {
							avatar: res.data.photo,
							firstName: res.data.firstName,
							lastName: res.data.lastName,
							status: res.data.status,
							studentId: res.data.studentId,
							locations: res.data.locations || [],
							inClass: res.data.inClass,
							contacts: res.data.contacts || 0
						};
						this.dropdownData;
						this.setBreadcrumData(res.data);
						// this.formDialogLocations.checkboxList = locations;
						// this.formDialogLocations.checkedData = locations;
						this.formDialogLocations.checkTitle = res.data.companyName
						let a = JSON.parse(sessionStorage.getItem('studentInfo'))
						a.status=res.data.status
						sessionStorage.setItem('studentInfo',JSON.stringify(a))
						sessionStorage.setItem('checkTitle',this.formDialogLocations.checkTitle)
					})
					.catch(() => {
						this.loading = false;
					});
			},
			_getLocations() {
				Ajax.get("/usermanage/student/getLocationByStuId", {
						id: this.studentId,
					})
					.then((res) => {
						this.formDialogLocations.checkboxList=[]
						this.formDialogLocations.checkboxList.push({
							id: res.data.id,
							name: res.data.name,
						});
						this.formDialogLocations.checkedData=[]
						this.formDialogLocations.checkedData.push(res.data.name);
						sessionStorage.setItem('checkedData',JSON.stringify(this.formDialogLocations.checkedData))
						sessionStorage.setItem('checkboxList',JSON.stringify(this.formDialogLocations.checkboxList))
					this.formDialogLocations.viewLocations=true
					this.formDialogLocations.viewLocations=false
					this.$forceUpdate()
					})
					.catch((err) => {
						console.log(err);
					});
			},
			setBreadcrumData(row) {
				console.log(13321)
				// this.$store.commit("SET_BREADCRUMDATA", [
				// 		 {
				// 		 		routeName: "locations",
				// 		 		name: sessionStorage.getItem("schoolName"),
				// 		 	},
				//   {
				//     routeName: "people",
				//     name: "People",
				//   },
				//   {
				//    name: `${row.firstName} ${row.lastName}`,
				//   }
				// ]);
				this.breadcrumbData = [
						 {
						 		routeName: "locations",
						 		name: sessionStorage.getItem("schoolName"),
						 	},
				  {
				    routeName: "people",
				    name: "People",
				  },
				  {
				   name: `${row.firstName} ${row.lastName}`,
				  }
				]
				// if (this.$route.query.people) {
				// 	this.$store.commit("SET_BREADCRUMDATA", [{
				// 			routeName: "locations",
				// 			name: this.$route.query.name,
				// 		},
				// 		{
				// 			routeName: "people",
				// 			name: "People",
				// 		},
				// 		{
				// 			name: `${row.firstName} ${row.lastName}`,
				// 		},
				// 	]);
				// } else if (this.$route.query.class) {

				// } else {
				// 	// this.$store.commit("SET_BREADCRUMDATA", [{
				// 	// 		routeName: "peopleStudentsPersonalInfo",
				// 	// 		name: "People",
				// 	// 	},
				// 	// 	{
				// 	// 		name: `${row.firstName} ${row.lastName}`,
				// 	// 	},
				// 	// ]);
				// 	// this.breadcrumbData = [{
				// 	// 		routeName: "peopleStudentsPersonalInfo",
				// 	// 		name: "People",
				// 	// 	},
				// 	// 	{
				// 	// 		name: `${row.firstName} ${row.lastName}`,
				// 	// 	},
				// 	// ];
				// }

			},
			_getSettingList(dataLevel, dataType, datalistName) {
				let data = {
					userId: this.user.userId,
					dataLevel,
					dataType,
				};
				this.loading = true;
				Ajax.post("/usermanage/setting/getList", data)
					.then((res) => {
						let response = res.data;
						this.loading = false;
						this[datalistName] = response || [];
					})
					.catch(() => {
						this.loading = false;
					});
			},
			_uploadCallbackLogo(val) {
				this.userInfo.avatar = val;
				let params = {
					photo: val,
					id: this.studentId,
				};
				Ajax.post("/usermanage/student/updateStudent", params).then((res) => {
					if (res.code === "0000") {
						this._getData();
						this.handleSuspend("Student has been edited");
					}
				});
			},
			handleCheck(val) {

				this.formDialogLocations.checkedData = val;
			},
			menuClick(action) {
				if(action.label){
					action=action.label
				}
				this.$emit("menuCallback", action);
				let callback = () => {
					this._updateStatus(action);
				};

				switch (action) {
					case "Return":
						action = "Returned";
						break;
					case "Enroll":
						action = "Enrolled";
						break;
					case "Withdraw":
						action = "Withdrawn";
						break;
					case "Defer":
						action = "Deferred";
						break;
					case "Delete":
						action = "Delete";
						callback = () => {
							this._deleteStatus(action);
						};
						break;
					case "Edit":
						this.editStudent(this.currentStudentData);
						return;
					default:
						return;
				}
				this.openMessageBox(this.messageObj[action], callback);
			},
			editStudent(row) {
				this.formDialogVisible = true;
				this.formDialogTitle = "Edit student";
				this.formDialogBtnTxt = "Save";
				this.selfStudentId = row.id;
				let formData = {
					firstName: row.firstName,
					lastName: row.lastName,
					studentId: row.studentId,
					schoolId: row.schoolId, // locationId
					userId: this.user.userId,
					id: row.id,
					auto: row.auto
				};
				if (formData.auto == 1) {
					this.isStudentIdEdit = true
				}
				this.formDataList.forEach((item) => {
					item.value = formData[item.key];
				});
				console.log(this.formDataList)
				this.is_first = false
				this.dialogData = Object.assign({}, this.query, formData);
				
			},
			handleSubmit() {
				this._updateStudent();
			},
			_updateStudent() {
				this.loading = true;
				let formData = {};
				this.formDataList.forEach((item) => {
					formData[item.key] = item.value;
					if (item.key === "auto") {
						console.log(item.value)
						if (item.value ==1) {
							formData.auto = "1";
						} else {
							formData.auto = "0";
						}
					}
				});
				formData.id = this.selfStudentId;
				Ajax.post("/usermanage/student/updateStudent", formData)
					.then((res) => {
						if (res.code !== "0000") {
							// this.handleSuspend(res.message);
						}
						this.formDialogVisible = false;
						this.loading = false;
						this.handleSuspend("Student has been edited");
						this._getData();
						this._getLocations()
						
						setTimeout(()=>{
							console.log(this.formDialogLocations.checkedData)
							console.log(this.formDialogLocations.checkboxList)
						},3000)
					})
					.catch(() => {
						this.loading = false;
					});
			},
			_updateStatus(action) {
				let formData = {
					id: this.currentStudentData.id,
					status: action,
					className: this.className,
					locationName: this.locationName,
				};
				if(action=='Returned'){
					formData = {
						status: "Enrolled",
						id: this.currentStudentData.id,
						className: this.className,
						locationName: this.locationName,
					};
				}
				console.log(action)
				this.loading = true;
				Ajax.post("/usermanage/student/updateStatus", formData)
					.then((res) => {
						if (res.code !== "0000") {}
						this.loading = false;
						this.handleSuspend(`Student has been ${action.toLowerCase()}`);
						// this.$router.go(0)
						
						this._getData();
						setTimeout(()=>{
							location.reload()
						},1000)
						
					})
					.catch(() => {
						this.loading = false;
					});
			},
			_deleteStatus(action) {
				let formData = {
					id: this.currentStudentData.id,
					status: action,
					className: this.className,
					locationName: this.locationName,
				};
				this.loading = true;
				Ajax.post("/usermanage/student/deleteById", formData)
					.then((res) => {
						this.loading = false;
						if (res.code == "0000") {
							// this.handleSuspend(`Student has been ${action}d`);
							// setTimeout(()=>{
								sessionStorage.setItem('delete','students')
									this.$router.push("/students");
							// },1000)
						
						} else {
							this.$router.push("/students");
							this.handleSuspend(res.message);
						}
					})
					.catch(() => {
						this.loading = false;
					});
			},
			openMessageBox(messageObj, callback) {
				this.$confirm(messageObj.desc, messageObj.title, {
					cancelButtonText: messageObj.cancelTxt,
					confirmButtonText: messageObj.confirmTxt,
					cancelButtonClass: "gg_cancel_button",
					confirmButtonClass: "gg_submit_button",
					customClass: "gg_confirm",
					showClose: false,
					callback: (action) => {
						if (action == "confirm") {
							callback(action);
						}
					},
				}).then(() => {
					this.handleSuspend(messageObj.snackbarContent);
				});
			},
			handleSuspend(content) {
				this.snackbar.isShow = true;
				this.snackbar.content = content;
			},
		},
		created() {
			this._getData();
			this._getLocations();
			this._getSettingList("location", "location", "locationList");
		},
	};
</script>
<style lang='less' scoped>
	.checkBoxCon {
		position: relative;
		width: 100px;
		flex-basis: 100px;
		padding-right: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.prompt {
			cursor: pointer;
		}
	}

	.form-dialog .content {
		min-height: 500px !important;
	}

	/deep/.md-checkbox {
		margin: 0;
	}

	/deep/.studentId {
		width: calc(50% - 100px) !important;
		flex-basis: calc(50% - 100px) !important;
	}
	.spec::after{
		height: 0;
	}
</style>
